import React from "react";
import Loader from "./Loader";

const Header = () => {
    return (
        <>
            <Loader />
            <header className="fixed-top header-fullpage top-border top-transparent wow fadeInDown">
                <div className="top-bar-right d-flex align-items-center text-md-left">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-auto">
                                <span className="mr-3"><i className="icofont-ui-touch-phone"></i> +32 465 185 662</span>
                                <span className="mr-3"><i className="icofont-ui-email"></i><a href="mailto:info@zgsolution.be">info@zgsolution.be</a></span>
                                <div className="dropdown d-inline-flex lang-toggle shadow-sm">
                                    {/* <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-hover="dropdown" data-animations="slideInUp slideInUp slideInUp slideInUp">
                                        <img src="images/us.svg" alt="" className="dropdown-item-icon" />
                                        <span className="d-inline-block d-lg-none">US</span>
                                        <span className="d-none d-lg-inline-block">United States</span> <i className="icofont-rounded-down"></i>
                                    </a>
                                    <div className="dropdown-menu dropdownhover-bottom dropdown-menu-right" role="menu">
                                        <a className="dropdown-item active" href="#">English</a>
                                        <a className="dropdown-item" href="#">Frence</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg bg-transparent">
                    <div className="container text-nowrap">
                        <div className="d-flex align-items-center w-100 col p-0">
                            <a className="navbar-brand rounded-bottom light-bg" href="home-one">
                                <img src="images/logo_footer.png" alt="" />
                            </a>
                        </div>
                        <div className="d-inline-flex request-btn order-lg-last col p-0">
                            {/* <a className="nav-link mr-2 ml-auto" href="#" id="search_home"><i className="icofont-search"></i></a> */}
                            {/* <a className="btn-theme icon-left bg-navy-blue no-shadow align-self-center" href="#" role="button" data-toggle="modal" data-target="#request_popup"><i className="icofont-list"></i> <span className="d-none d-sm-inline-block"> Request Quote</span></a> */}
                            <button className="navbar-toggler x collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarCollapse" data-hover="dropdown" data-animations="slideInUp slideInUp slideInUp slideInUp">

                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link" href="/">HOME</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="about">About Us</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="services">Services</a>
                                </li> */}
                                <li className="nav-item dropdown">
                                    <a className="nav-link" href="contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Header;
