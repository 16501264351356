import React, { useEffect } from "react";

const Content = () => {
    return (
        <>
            <main id="body-content">

            {/* <section className="bg-white wide-tb-100">
            <div className="container pos-rel">
                <div className="row">
                <div className="col-md-12 col-lg-6">
                    <p><img src="images/years_img.png" alt="" className="mb-5"/></p>
                    <p>Having implemented a variety of ecological, economic and social initiatives, the family-owned company, which has a history going back 500 years, is considered a pioneer in terms of sustainable business today.</p>
                    <p>The Corporate Movie of the transport and logistics service provider Globax Logistics. GL not only moves goods and data, but also people who are connected to the orange network.</p>
                    <div className="img-icon mt-4 d-flex align-items-center">
                    <img src="images/team/team-1.jpg"/>
                    <h3>
                        Yazan ZG
                        <span>Founder & CEO</span>
                    </h3>
                    </div>
                </div>

                <div className="w-100 spacer-50 d-none d-md-block d-lg-none d-sm-none"></div>
                <div className="col-md-12 col-lg-6">

                    <div className="row">
                        <div className="col-12 col-md-6">              
                        <div className="icon-box-6">
                            <i className="icofont-box"></i>
                            <h3 className="h3-xs txt-blue">Packaging and Storage</h3>
                            <p>Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                            <a href="#" className="btn-arrow bg-navy-blue"><i className="icofont-swoosh-right"></i></a>
                        </div>              
                        </div>

                        <div className="col-12 col-md-6">              
                        <div className="icon-box-6">
                            <i className="icofont-safety"></i>
                            <h3 className="h3-xs txt-blue">Safety & Quality</h3>
                            <p>Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                            <a href="#" className="btn-arrow bg-navy-blue"><i className="icofont-swoosh-right"></i></a>
                        </div>              
                        </div>

                        <div className="w-100 spacer-50 d-none d-lg-none d-md-block d-lg-block d-sm-none"></div>

                        <div className="col-12 col-md-6">              
                        <div className="icon-box-6">
                            <i className="icofont-tree-alt"></i>
                            <h3 className="h3-xs txt-blue">Care for Environment</h3>
                            <p>Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                            <a href="#" className="btn-arrow bg-navy-blue"><i className="icofont-swoosh-right"></i></a>
                        </div>              
                        </div>

                        <div className="col-12 col-md-6">              
                        <div className="icon-box-6">
                            <i className="icofont-delivery-time"></i>
                            <h3 className="h3-xs txt-blue">Delivery On Time</h3>
                            <p>Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                            <a href="#" className="btn-arrow bg-navy-blue"><i className="icofont-swoosh-right"></i></a>
                        </div>              
                        </div>
                    </div>

                </div>

                </div>
                
            </div>
            </section>

            <section className="bg-light-gray wide-tb-100 bg-wave">
            <div className="container pos-rel">
                <div className="row">
                <div className="col-md-6">
                    <img src="images/about_img_2.jpg" className="bordered-img" alt=""/>
                </div>
                
                <div className="col-md-6 ml-auto why-choose wow fadeInRight" data-wow-duration="0" data-wow-delay="0.6s">
                    <h1 className="heading-main text-left wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                    <span>ABOUT US</span>
                    Welcome To Zg Solution Family
                    </h1>

                    <p>We provide you with a transport service with our trucks with a capacity of 25 tons and loading till 38 pallets.</p>
                    <div className="skillbar-wrap mt-5">
                        <div className="clearfix">
                            Logistics
                        </div>
                        <div className="skillbar" data-percent="75%">
                            <div className="skillbar-percent">75%</div>
                            <div className="skillbar-bar"></div>
                        </div>             
                    </div>
                    
                    <div className="skillbar-wrap">
                        <div className="clearfix">
                            Truck Rental
                        </div>
                        <div className="skillbar" data-percent="50%">
                            <div className="skillbar-percent">50%</div>
                            <div className="skillbar-bar"></div>
                        </div>             
                    </div>
                    
                    <div className="skillbar-wrap">
                        <div className="clearfix">
                            Courier
                        </div>
                        <div className="skillbar" data-percent="95%">
                            <div className="skillbar-percent">95%</div>
                            <div className="skillbar-bar"></div>
                        </div>             
                    </div>
                    <div className="skillbar-wrap">
                        <div className="clearfix">
                            Air Transport
                        </div>
                        <div className="skillbar" data-percent="60%">
                            <div className="skillbar-percent">60%</div>
                            <div className="skillbar-bar"></div>
                        </div>             
                    </div>
                    <div className="skillbar-wrap">
                        <div className="clearfix">
                            Support
                        </div>
                        <div className="skillbar" data-percent="95%">
                            <div className="skillbar-percent">95%</div>
                            <div className="skillbar-bar"></div>
                        </div>             
                    </div>
                </div>
                </div>
                
            </div>
            </section>

            <section className="bg-white wide-tb-100">
            <div className="container pos-rel">
                <div className="row">
                <div className="img-business-man">
                    <img src="images/courier-man.png" alt=""/>
                </div>
                <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                    <h1 className="heading-main">
                    <span>Our Goodness</span>
                    What Makes Us Special
                    </h1>
                </div>
                <div className="col-md-6 ml-auto">
                    <div className="row">
                        <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">              
                        <div className="icon-box-3 mb-5 bg-sky-blue">
                            <div className="media">
                                <div className="service-icon mr-5">
                                    <i className="icofont-box bg-white"></i>
                                </div>
                                <div className="service-inner-content media-body">
                                    <h4 className="h4-md">Packaging and Storage</h4>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae. Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                                </div>
                            </div>
                        </div>              
                        </div>

                        <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">              
                        <div className="icon-box-3 mb-5 bg-sky-blue">
                            <div className="media">
                                <div className="service-icon mr-5">
                                    <i className="icofont-shield bg-white"></i>
                                </div>
                                <div className="service-inner-content media-body">
                                    <h4 className="h4-md">Safety & Quality</h4>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae. Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                                </div>
                            </div>
                        </div>              
                        </div>

                        <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.4s">              
                        <div className="icon-box-3 bg-sky-blue">
                            <div className="media">
                                <div className="service-icon mr-5">
                                    <i className="icofont-tree-alt bg-white"></i>
                                </div>
                                <div className="service-inner-content media-body">
                                    <h4 className="h4-md">Care for Environment</h4>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae. Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                                </div>
                            </div>
                        </div>              
                        </div>
                    </div>
                </div>
                </div>
                
            </div>
            </section> */}

            <section className="wide-tb-100 bg-scroll counter-bg pos-rel">
            <div className="bg-overlay blue opacity-50"></div>
            <div className="container">
                <div className="row">
                

                    <div className="col counter-style-1 col-6 col-lg-6 col-sm-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                    <p><i className="icofont-globe"></i></p>
                    <span className="counter">110</span>
                    <span>+</span>
                    <div>
                        Clients Worldwide
                    </div>
                    </div>

                    <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>


                    <div className="col counter-style-1 col-6 col-lg-6 col-sm-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                    <p><i className="icofont-umbrella-alt"></i></p>
                    <span className="counter">2340</span>
                    <div>
                        Tonnes Transported
                    </div>
                    </div>
                </div>
            </div>        
            </section>

            {/* <section className="bg-white wide-tb-100">
            <div className="container pos-rel">
                <div className="row">
                <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                    <h1 className="heading-main">
                    <span>our Gallery</span>
                    Photo Showcase
                    </h1>
                </div>
                </div>

                <div id="js-styl2-mosaic" className="cbp">
                        <div className="cbp-item design">
                            <div className="gallery-link">
                            <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                            </div>
                            <a href="images/portfolio/fullwidth/img-1.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum">
                                <div className="cbp-caption-defaultWrap">
                                    <img src="images/portfolio/fullwidth/img-1.jpg" alt=""/>
                                </div>
                                <div className="cbp-caption-activeWrap">
                                    <div className="cbp-l-caption-alignCenter">
                                        <div className="cbp-l-caption-body">
                                            <i className="icofont-search icofont-2x txt-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div className="cbp-item design">
                            <div className="gallery-link">
                            <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                            </div>
                            <a href="images/portfolio/fullwidth/img-2.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum
            ">
                                <div className="cbp-caption-defaultWrap">
                                    <img src="images/portfolio/fullwidth/img-2.jpg" alt=""/>
                                </div>
                                <div className="cbp-caption-activeWrap">
                                    <div className="cbp-l-caption-alignCenter">
                                        <div className="cbp-l-caption-body">
                                            <i className="icofont-search icofont-2x txt-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div className="cbp-item illustration">
                            <div className="gallery-link">
                            <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                            </div>
                            <a href="images/portfolio/fullwidth/img-3.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum
            ">
                                <div className="cbp-caption-defaultWrap">
                                    <img src="images/portfolio/fullwidth/img-3.jpg" alt=""/>
                                </div>
                                <div className="cbp-caption-activeWrap">
                                    <div className="cbp-l-caption-alignCenter">
                                        <div className="cbp-l-caption-body">
                                            <i className="icofont-search icofont-2x txt-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div className="cbp-item photography">
                            <div className="gallery-link">
                            <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                            </div>
                            <a href="images/portfolio/fullwidth/img-7.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum
            ">
                                <div className="cbp-caption-defaultWrap">
                                    <img src="images/portfolio/fullwidth/img-7.jpg" alt=""/>
                                </div>
                                <div className="cbp-caption-activeWrap">
                                    <div className="cbp-l-caption-alignCenter">
                                        <div className="cbp-l-caption-body">
                                            <i className="icofont-search icofont-2x txt-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div className="cbp-item identity">
                            <div className="gallery-link">
                            <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                            </div>
                            <a href="images/portfolio/fullwidth/img-8.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum
            ">
                                <div className="cbp-caption-defaultWrap">
                                    <img src="images/portfolio/fullwidth/img-8.jpg" alt=""/>
                                </div>
                                <div className="cbp-caption-activeWrap">
                                    <div className="cbp-l-caption-alignCenter">
                                        <div className="cbp-l-caption-body">
                                            <i className="icofont-search icofont-2x txt-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div className="cbp-item business">
                            <div className="gallery-link">
                            <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                            </div>
                            <a href="images/portfolio/fullwidth/img-9.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum
            ">
                                <div className="cbp-caption-defaultWrap">
                                    <img src="images/portfolio/fullwidth/img-9.jpg" alt=""/>
                                </div>
                                <div className="cbp-caption-activeWrap">
                                    <div className="cbp-l-caption-alignCenter">
                                        <div className="cbp-l-caption-body">
                                            <i className="icofont-search icofont-2x txt-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div className="cbp-item photography">
                            <div className="gallery-link">
                            <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                            </div>
                            <a href="images/portfolio/fullwidth/img-10.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum">
                                <div className="cbp-caption-defaultWrap">
                                    <img src="images/portfolio/fullwidth/img-10.jpg" alt=""/>
                                </div>
                                <div className="cbp-caption-activeWrap">
                                    <div className="cbp-l-caption-alignCenter">
                                        <div className="cbp-l-caption-body">
                                            <i className="icofont-search icofont-2x txt-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div className="cbp-item business">
                            <div className="gallery-link">
                            <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                            </div>
                            <a href="images/portfolio/fullwidth/img-11.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum">
                                <div className="cbp-caption-defaultWrap">
                                    <img src="images/portfolio/fullwidth/img-11.jpg" alt=""/>
                                </div>
                                <div className="cbp-caption-activeWrap">
                                    <div className="cbp-l-caption-alignCenter">
                                        <div className="cbp-l-caption-body">
                                            <i className="icofont-search icofont-2x txt-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                
            </div>
            </section> */}

            {/* <section className="bg-sky-blue wide-tb-100 pb-5 why-choose">
            <div className="container pos-rel">
                <div className="contact-map-bg">
                <img src="images/map-bg.png" alt=""/>
                </div>
                <div className="row piecharts" id="pie-charts">
                <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                    <h1 className="heading-main">
                    <span>Looking for more?</span>
                    Watch Our Intro Video
                    </h1>
                </div>
                <div className="col-md-6 offset-md-3">
                    <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <span className="chart" data-percent="90">
                            <span className="percent"></span>
                        </span>
                        <div className="skill-name">Road Transport</div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <span className="chart" data-percent="90">
                            <span className="percent"></span>
                        </span>
                        <div className="skill-name">Logistics</div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <span className="chart" data-percent="95">
                            <span className="percent"></span>                                        
                        </span>
                        <div className="skill-name">Truck Rental</div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
            <section className="wide-tb-60 p-0 bg-sky-blue">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 wow pulse animated" data-wow-duration="0" data-wow-delay="0.7s">
                    <div className="bg-fixed pos-rel video-popup">
                        <div className="bg-overlay black opacity-50"></div>
                        <div className="zindex-fixed pos-rel">
                        <a href="#" className="play-video"><i className="icofont-play icofont-4x"></i></a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </section>

            <section className="wide-tb-100 mb-spacer-md">
            <div className="container wide-tb-100 pb-0">
                <div className="row">
                    <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                    <h1 className="heading-main">
                        <span>What Our</span>
                        Customers Saying
                    </h1>
                    </div>
                    <div className="col-sm-12">
                    <div className="owl-carousel owl-theme" id="home-client-testimonials">

                        <div className="item">
                        <div className="client-testimonial bg-wave">
                            <div className="media">
                                <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                                    <img src="images/team_1.jpg" alt=""/>
                                </div>
                                <div className="client-inner-content media-body">
                                    <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Aliquam gravida, urna quis ornare imperdiet, </p>
                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className="item">
                        <div className="client-testimonial bg-wave">
                            <div className="media">
                                <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                                    <img src="images/team_2.jpg" alt=""/>
                                </div>
                                <div className="client-inner-content media-body">
                                    <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Aliquam gravida, urna quis ornare imperdiet, </p>
                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className="item">
                        <div className="client-testimonial bg-wave">
                            <div className="media">
                                <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                                    <img src="images/team_3.jpg" alt=""/>
                                </div>
                                <div className="client-inner-content media-body">
                                    <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Aliquam gravida, urna quis ornare imperdiet, </p>
                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                </div>
                            </div>
                        </div>
                        </div>

                    </div>
                    </div>
                </div>
            </div>        
            </section> */}

            <section className="wide-tb-80 bg-navy-blue callout-style-1 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 mb-0">
                    <h4 className="h4-xl">Interested in working with Zg Solution?</h4>
                    </div>
                    <div className="col">
                    {/* <div className="center-text">
                        We don’t just manage suppliers, we micro-manage them. We have a consultative, personalized approach
                    </div> */}
                    </div>
                    <div className="col-sm-auto">
                    <a href="tel:+32465185662"  className="btn-theme bg-white light">Get In Touch <i className="icofont-rounded-right"></i></a>
                    </div>
                </div>
            </div>
            </section>
            {/* <section className="wide-tb-100">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                    <h1 className="heading-main">
                        <span>COMPANY'S NEWS</span>
                        Recent Posts
                    </h1>
                    </div>

                    <div className="col-sm-12 col-md-4 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0.1s">
                        <div className="blog-warp">
                        <img src="images/blog_img_1.jpg" alt="" className="rounded"/>
                        <div className="meta-box"><a href="#">Business</a> <span>/</span>    September 28, 2018</div>
                        <h4 className="h4-md mb-3"><a href="#">Freight Payment and Auditing Services</a></h4>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantiumg</p>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.1s">
                        <div className="blog-warp">
                        <img src="images/blog_img_2.jpg" alt="" className="rounded"/>
                        <div className="meta-box"><a href="#">Business</a> <span>/</span>    September 28, 2018</div>
                        <h4 className="h4-md mb-3"><a href="#">Freight Payment and Auditing Services</a></h4>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantiumg</p>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 wow fadeInRight" data-wow-duration="0" data-wow-delay="0.1s">
                        <div className="blog-warp">
                        <img src="images/blog_img_3.jpg" alt="" className="rounded"/>
                        <div className="meta-box"><a href="#">Business</a> <span>/</span>    September 28, 2018</div>
                        <h4 className="h4-md mb-3"><a href="#">Freight Payment and Auditing Services</a></h4>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantiumg</p>
                        </div>
                    </div>

                </div>
            </div>
            </section> */}

            {/* <section className="wide-tb-100 bg-fixed clients-bg pos-rel">
            <div className="bg-overlay blue opacity-80"></div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                    <h1 className="heading-main">
                        <span>SOME OF OUR</span>
                        Clients
                    </h1>
                    </div>

                    <div className="col-sm-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                    <div className="owl-carousel owl-theme" id="home-clients">

                        <div className="item">
                        <img src="images/clients/client1.png" alt=""/>
                        </div>

                        <div className="item">
                        <img src="images/clients/client2.png" alt=""/>
                        </div>

                        <div className="item">
                        <img src="images/clients/client3.png" alt=""/>
                        </div>

                        <div className="item">
                        <img src="images/clients/client4.png" alt=""/>
                        </div>

                        <div className="item">
                        <img src="images/clients/client5.png" alt=""/>
                        </div>

                        <div className="item">
                        <img src="images/clients/client6.png" alt=""/>
                        </div>

                    </div>
                    </div>
                </div>
            </div>        
            </section> */}

            <section className="map-bg">
            {/* <div className="contact-details row d-flex">
                <div className="col">
                <h4>Germany</h4>
                <p><i className="icofont-phone"></i> +1 (408) 786 - 5117</p>
                <div className="text-nowrap"><i className="icofont-email"></i> <a href="#">germany@zgsolution.be</a></div>
                </div>
                <div className="col">
                <h4>Spain</h4>
                <p><i className="icofont-phone"></i> +1 (408) 786 - 5117</p>
                <div className="text-nowrap"><i className="icofont-email"></i> <a href="#">spain@zgsolution.be</a></div>
                </div>
            </div> */}
            <div id="map-holder" className="pos-rel">
                <div id="map_extended">
                    <p>This will be replaced with the Google Map.</p>
                </div>
            </div>
            </section> 

            </main>
        </>
    );
};
export default Content;
