import React from "react";

const Slider = () => {
    const Styles = {
        tpCaption: {
            zIndex: 3, whiteSpace: "nowrap", fontWeight: "800", backgroundColor: "rgba(41, 46, 49, 1.00)", borderColor: "rgba(255, 255, 255, 0)", outline: "none", boxShadow: "none", boxSizing: "borderBox", MozBoxSizing: "border-box", WebkitBoxSizing: "border-box",
        }
    }
    return (
        <>
            <div className="slider bg-navy-blue">
                <div id="rev_slider_1078_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="classNameic4export" data-source="gallery" style={{ margin: "0px auto", backgroundColor: "transparent", padding: "0px", marginTop: "0px", marginBottom: "0px" }}>
                    <div id="rev_slider_1078_1" className="rev_slider fullscreenbanner" style={{ display: "none" }}>
                        <ul>
                     
                            <li data-index="rs-3045" data-transition="zoomout" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000" data-thumb="rev-slider/assets/images/datcolor-100x50.jpg" data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off" data-title="Intro" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                                <img src="images/banner_slider_5.jpeg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" className="rev-slidebg" data-no-retina />

                                <div className="tp-caption NotGeneric-Title tp-resizeme"
                                    id="slide-3-layer-1"
                                    data-x="left" data-hoffset="60"
                                    data-y="center" data-voffset="-120"
                                    data-width="['auto','auto','auto','auto']"
                                    data-height="['auto','auto','auto','auto']"
                                    data-transform_idle="o:1;"
                                    data-fontsize="['70','70','70','45']"
                                    data-transform_in="y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;s:1500;e:Power3.easeInOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                                    data-start="700"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    style={{ zIndex: "1", whiteSpace: "nowrap", color:"#000" }}><span className="slider-small">Ready For Any</span> <br />Obstacle
                                </div>

                                <div className="tp-caption NotGeneric-Title tp-resizeme"
                                    id="slide-3-layer-2"
                                    data-x="left" data-hoffset="60"
                                    data-y="center" data-voffset="10"
                                    data-width="['auto','auto','auto','auto']"
                                    data-height="['auto','auto','auto','auto']"
                                    data-transform_idle="o:1;"

                                    data-transform_in="x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;s:1500;e:Power3.easeInOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                                    data-start="1400"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    style={{ zIndex: 2, whiteSpace: "nowrap", fontSize: "18px", lineHeight: "30px", color:"#000" } }>We are here to ,<br /> Help you <br />ZG Solution
                                </div>

                            </li>
                        </ul>
                        <div className="tp-bannertimer tp-bottom" style={{ height: "7px", backgroundColor: `rgba(255, 255, 255, 0.25)` }}></div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Slider;
