import React from "react";
import Loader from "./Loader";

const Header = () => {
	return (
		<>
			<Loader />
			<header className="fixed-top header-fullpage bordered-nav wow fadeInDown">
				<div className="top-bar-right d-flex align-items-center text-md-left">
					<div className="container px-0">
						<div className="row align-items-center">
							<div className="col d-flex">
								<div className="top-text">
									<small className="txt-black">Emaii Us</small>
									<a href="mailto:info@zgsolution.be">info@zgsolution.be</a>
								</div>
								<div className="top-text">
									<a href="tel:0032465185662"></a><small className="txt-black">Phone Number</small>
									+32465185662
								</div>
							</div>
							<div className="col-md-auto">
								<div className="dropdown d-inline-flex lang-toggle">
									{/* <a href="#" className="dropdown-toggle btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-hover="dropdown" data-animations="slideInUp slideInUp slideInUp slideInUp">
										<img src="images/us.svg" alt="" className="dropdown-item-icon" />
										<span className="d-inline-block d-lg-none">US</span>
										<span className="d-none d-lg-inline-block">United States</span> <i className="icofont-rounded-down"></i>
									</a>
									<div className="dropdown-menu dropdownhover-bottom dropdown-menu-right" role="menu">
										<a className="dropdown-item active" href="#">English</a>
										<a className="dropdown-item" href="#">Deutsch</a>
										<a className="dropdown-item" href="#">Español&lrm;</a>
									</div> */}
								</div>
								<div className="d-inline-flex request-btn ml-2">
									{/* <a className="btn-theme icon-left bg-orange no-shadow d-none d-lg-inline-block align-self-center" href="#" role="button" data-toggle="modal" data-target="#request_popup"><i className="icofont-page"></i> Request Quote</a> */}
								</div>
							</div>
						</div>
					</div>
				</div>

				<nav className="navbar navbar-expand-lg bg-transparent">
					<div className="container text-nowrap bdr-nav px-0">
						<div className="d-flex mr-auto">
							<a className="navbar-brand rounded-bottom light-bg" href="home-one">
								<img src="images/logo_white.png" alt="" />
							</a>
						</div>
						{/* <span className="order-lg-last d-inline-flex request-btn">
							<a className="nav-link mr-2 ml-auto" href="#" id="search_home"><i className="icofont-search"></i></a>
						</span> */}
						<button className="navbar-toggler x collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarCollapse" data-hover="dropdown" data-animations="slideInUp slideInUp slideInUp slideInUp">
							<ul className="navbar-nav ml-auto">
								<li className="nav-item">
									<a className="nav-link" href="/">HOME</a>

								</li>
								<li className="nav-item">
									<a className="nav-link" href="about">About Us</a>
								</li>
								{/* <li className="nav-item">
									<a className="nav-link" href="services">Services</a>
								</li> */}
								<li className="nav-item dropdown">
									<a className="nav-link" href="contact">Contact Us</a>

								</li>
							</ul>
						</div>
					</div>
				</nav>
			</header>
		</>
	);
};

export default Header;
